/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import _omit from 'lodash/omit';
import classNames from 'classnames';
import Panel from 'react-bootstrap/lib/Panel';
import PropTypes from 'prop-types';
import styles from './Collapsible.module.scss';
import './Collapsible.module.scss';

type Props = {
  bsClass?: string;
  bsStyle?: string;
  collapsible?: boolean;
  defaultExpanded?: boolean;
  eventKey?: any;
  expanded?: boolean;
  footer?: any;
  header?: any;
  headerRole?: string;
  id?: any;
  onEnter?: Function;
  onEntered?: Function;
  onEntering?: Function;
  onExit?: Function;
  onExited?: Function;
  onExiting?: Function;
  onSelect?: Function;
  panelRole?: string;
  className?: string;
  children?: any;
  renderHeader?: Function;
  renderContent?: Function;
  unmountOnExit?: boolean;
  navTitle?: string;
};

type State = {
  expanded: any;
};

const backwardCompatibleSelects = ['handleSelect', 'handleClickTitle'];
const omitProps = ['expanded', 'defaultExpanded', 'navTitle'];

/**
 * A Wrapper for Panels
 * https://react-bootstrap.github.io/components.html#panels
 * @param {Props} props
 * @return {Element}
 */
export default class Collapsible extends React.Component<Props, State> {
  static defaultProps: Object = {
    defaultExpanded: true,
  };

  static childContextTypes: Object = {
    closeCollapsible: PropTypes.func,
  };

  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: 'expanded' in props ? props.expanded : props.defaultExpanded,
    };
    this._handleSelect = this.handleSelect.bind(this);
  }

  getChildContext(): Object {
    return {
      closeCollapsible: (e: Object) => {
        this.handlePanelSelect(e);
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (typeof nextProps.expanded === 'boolean') {
      this.setState({
        expanded: nextProps.expanded,
      });
    }
  }

  _handleSelect: Function;

  handlePanelSelect(e: Object) {
    if (!this.panel) {
      return;
    }
    for (const selectHandler of backwardCompatibleSelects) {
      if (
        this.panel.hasOwnProperty(selectHandler) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error fix later
        typeof this.panel[selectHandler] === 'function'
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error fix later
        this.panel[selectHandler](e);
      }
    }
  }

  handleSelect(): void {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error fix later
  panel: Panel;

  render() {
    const { children, className, renderHeader, header } = _omit(
      this.props,
      omitProps,
    );
    const { expanded } = this.state;

    return (
      <Panel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error fix later
        onToggle={this._handleSelect}
        className={classNames(styles.Collapsible, className)}
        expanded={expanded}
        ref={(panel: any) => (this.panel = panel)}
      >
        {(renderHeader || header) && (
          <Panel.Heading className={classNames(expanded && styles.expanded)}>
            <Panel.Title toggle>
              {renderHeader ? renderHeader(expanded) : header}
            </Panel.Title>
          </Panel.Heading>
        )}
        <Panel.Collapse
          style={{ height: 0 }}
          className={classNames(
            styles.panelCollapsible,
            expanded && styles.expanded,
          )}
        >
          <Panel.Body>{children}</Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}
