import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.hydrate(
  <React.StrictMode>
    <BrowserRouter>
      <AppHeader />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root-header') as HTMLElement,
);

ReactDOM.hydrate(
  <React.StrictMode>
    <BrowserRouter>
      <AppFooter />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root-footer') as HTMLElement,
);
