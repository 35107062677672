/** @flow */
import { withStateHandlers } from 'recompose';

const mobileMenuStateEnhancer = withStateHandlers(
  ({ isMobileMenuOpen = false }) => ({
    isMobileMenuOpen,
  }),
  {
    closeMobileMenu: () => () => ({
      isMobileMenuOpen: false,
    }),
    openMobileMenu: () => () => ({
      isMobileMenuOpen: true,
    }),
  },
);

export default mobileMenuStateEnhancer;
