import React, { FC, useState, useRef, useEffect } from 'react';
import styles from './Dropdown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

interface DropdownProps {
  title: string;
  options?: string[];
  disabled?: boolean;
  defaultValue?: string;
  type?: string;
  onSelect?: (selectedOption: string, type?: string) => void;
}

const Dropdown: FC<DropdownProps> = ({
  title,
  options,
  disabled = false,
  defaultValue,
  type,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const onOptionClicked = (value: string) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    if (onSelect) {
      onSelect(value, type);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption(defaultValue || '');
  }, [defaultValue]);

  if (!options) {
    return null;
  }

  return (
    <div ref={dropdownRef}>
      <div
        className={classNames(
          styles.dropdownHeader,
          selectedOption && styles.dropdownHeaderItemSelected,
          {
            [styles.disabled]: disabled,
          },
        )}
        onClick={toggleDropdown}
      >
        <div>
          <div className={classNames(styles.title)}>{title}</div>
          <div className={classNames(styles.selectedOption)}>
            {selectedOption || defaultValue || ''}
          </div>
        </div>

        <FontAwesomeIcon
          className={classNames(styles.icon)}
          icon={faChevronDown}
        />
      </div>
      {isOpen && (
        <div className={classNames(styles.dropdownListContainer)}>
          <ul className={classNames(styles.dropdownList)}>
            {options.map((option, index) => (
              <li
                className={classNames(
                  styles.dropdownListItem,
                  option === selectedOption && styles.dropdownListItemSelected,
                )}
                onClick={onOptionClicked(option)}
                key={index}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
