import React, { useEffect } from 'react';
import { mobileDeviceDetect } from '../utils';

const Logo = (props) => {
  const { logoUrl } = props;
  const [isMobile, setIsMobile] = React.useState(mobileDeviceDetect());
  const [width, setWidth] = React.useState(333);
  const [height, setHeight] = React.useState(20);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileDeviceDetect());
      isMobile ? setWidth(226) : setWidth(333);
      isMobile ? setHeight(12) : setHeight(20);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <img src={logoUrl} alt="travelcentive.com" width={width} height={height} />
  );
};

export default Logo;
