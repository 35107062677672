import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import BurgerMenu from './menuFactory';
import styles from '../../../components/OffCanvas/OffCanvas.module.scss';

export default class OffCanvas extends Component {
  static defaultProps = {
    side: 'left',
    enabled: true,
    addBodyClassOnOpen: true,
    openedBodyClass: 'has-offcanvas',
  };

  props;

  constructor(props) {
    super(props);
    this._menu = BurgerMenu({});
    this._handleStateChange = this.handleStateChange.bind(this);
  }

  handleToggleMenu() {
    this.refs.menu.toggleMenu();
    if (this.props.addBodyClassOnOpen) {
      document.body.classList.toggle(this.props.openedBodyClass);
    }
  }

  handleStateChange({ isOpen }) {
    const {
      onExit,
      onExited,
      onEnter,
      onEntered,
      addBodyClassOnOpen,
      openedBodyClass,
    } = this.props;
    if (!isOpen) {
      if (addBodyClassOnOpen) {
        document.body.classList.remove(openedBodyClass);
      }
      if (onExit) {
        onExit();
      }
      if (onExited) {
        onExited();
      }
    } else {
      if (addBodyClassOnOpen) {
        document.body.classList.add(openedBodyClass);
      }
      if (onEnter) {
        onEnter();
      }
      if (onEntered) {
        onEntered();
      }
    }
  }
  _menu;
  _handleStateChange;

  render() {
    const {
      enabled,
      children,
      side,
      outerContainerId,
      className,
      burgerButton,
      crossButton,
      ...rest
    } = this.props;
    const Menu = this._menu;
    const uniqueOuterContainerId = _.uniqueId('outer-container');
    return (
      <div
        className={classNames(styles.Container, className)}
        id={outerContainerId}
      >
        {enabled && (
          <Menu
            {...rest}
            burgerButton={burgerButton}
            crossButton={crossButton}
            right={side === 'right'}
            ref="menu"
            outerContainerId={outerContainerId || uniqueOuterContainerId}
            onStateChange={this._handleStateChange}
          >
            {children}
          </Menu>
        )}
      </div>
    );
  }
}
