import React, { FC, useState, useEffect } from 'react';
import styles from './Modal.module.scss';
import classNames from 'classnames';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface ModalProps {
  isOpen?: boolean;
  body: React.ReactNode;
  buttonText: string;
  Trigger?: FC;
  actionDisabled?: boolean;
  onClick?: () => void;
  closeModal?: () => void;
  onSubmit?: () => void;
}

const Modal: FC<ModalProps> = ({
  isOpen: isOp,
  body,
  buttonText,
  actionDisabled,
  Trigger,
  onClick,
  closeModal,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(isOp || false);

  useEffect(() => {
    setIsOpen(isOp || false);
  }, [isOp]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    if (onClick) onClick();
    if (closeModal) closeModal();
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
    toggleModal();
  };

  return (
    <div>
      {Trigger && (
        <div onClick={toggleModal}>
          <Trigger />
        </div>
      )}

      {isOpen && (
        <div className={classNames(styles.Modal)}>
          <div className={classNames(styles.closeIcon)} onClick={toggleModal}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
          <div className={classNames(styles.Body)}>{body}</div>
          <div className={classNames(styles.Footer)}>
            <Button
              text={buttonText}
              onClick={handleSubmit}
              disabled={actionDisabled}
            />
          </div>
        </div>
      )}

      {isOpen && (
        <div className={classNames(styles.Overlay)} onClick={toggleModal} />
      )}
    </div>
  );
};

export default Modal;
