import { APIResponse } from '../../../types';
import { useEffect, useState } from 'react';

export const useHeaderData = (slug: string, preview = false, locale = 'de') => {
  const [headerData, setHeaderData] = useState<APIResponse | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(
    function fetchHeaderData() {
      setError(false);
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/header/${slug}?locale=${locale}&preview=${preview}`,
      )
        .then((response) => {
          setHeaderData(null);
          return response.json() as Promise<APIResponse | APIResponse[]>;
        })
        .then((data) => {
          if (Array.isArray(data)) {
            const localeData = data.find((item) => item?.locale === locale);
            setHeaderData(localeData ? localeData : null);
          } else if (data?.locale === locale) {
            setHeaderData(data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching header data:', error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [slug, locale, preview],
  );

  return {
    data: headerData,
    error,
    loading,
  };
};
