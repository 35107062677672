import React from 'react';
import Header from './components/Header/Travelcentive';
import {
  DEFAULT_MODE,
  MODE_HEADER,
  isPreviewEnabled,
} from './components/utils';
import { useMode, useSlug } from './hooks';

export default function App() {
  const enabledPreview = isPreviewEnabled();
  const slug = useSlug();
  const mode = useMode();

  return (
    <>
      {(mode === DEFAULT_MODE || mode === MODE_HEADER) && (
        <Header preview={enabledPreview} slug={slug} />
      )}
    </>
  );
}
