import React from 'react';
import classNames from 'classnames';
import styles from './InfoContent.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoContent = (props) => {
  const { label, icon, url, customClass } = props;

  if (url) {
    return (
      <a href={url} className={styles.link}>
        <span className={classNames(styles.link, customClass)}>
          <FontAwesomeIcon
            className={classNames(styles.icon, 'icon-phone')}
            icon={icon}
          />
          <span className={styles.number}>{label}</span>
        </span>
      </a>
    );
  }

  return (
    <span className={classNames(styles.link, customClass)}>
      <FontAwesomeIcon
        className={classNames(styles.icon, 'icon-phone')}
        icon={icon}
      />
      <span className={styles.number}>{label}</span>
    </span>
  );
};

export default InfoContent;
