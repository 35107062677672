import React, { Component } from 'react';
import _omit from 'lodash/omit';
import OffCanvas from '../../lib/tsBob/OffCanvas';

import './OffCanvas.module.scss';

import { styleBodyOnOpen, styleBodyOnClose } from '../utils';

class EnhancedOffcanvas extends Component {
  static defaultProps = {
    addBodyClassOnOpen: false,
    disableEnhancement: false,
  };

  props;

  componentWillUnmount() {
    if (!this.props.disableEnhancement) {
      styleBodyOnClose();
    }
  }

  enhancedOnEnter = () => {
    const { onEnter, disableEnhancement } = this.props;
    if (onEnter) {
      onEnter();
    }
    if (!disableEnhancement) {
      styleBodyOnOpen();
    }
  };

  enhancedOnExit = () => {
    const { onExit, disableEnhancement } = this.props;
    if (onExit) {
      onExit();
    }
    if (!disableEnhancement) {
      styleBodyOnClose();
    }
  };

  render() {
    const props = _omit(this.props, ['onEnter', 'onExit']);
    const { openedBodyClass, ...rest } = props;

    return (
      <OffCanvas
        openedBodyClass={openedBodyClass}
        onEnter={this.enhancedOnEnter}
        onExit={this.enhancedOnExit}
        {...rest}
      />
    );
  }
}

export default EnhancedOffcanvas;
