import { useEffect, useState } from 'react';

type FooterContent = {
  copyright?: string;
  logo?: {
    name?: string;
    url?: string;
    mime?: string;
    width?: number;
    height?: number;
  };
  links?: {
    id?: number;
    label?: string;
    path?: string;
  }[];
};

type FooterData = {
  id?: number;
  slug?: string;
  locale?: string;
  content?: FooterContent[];
};

export const useFooterData = (slug: string, preview = false, locale = 'de') => {
  const [footerData, setFooterData] = useState<FooterData | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(
    function fetchFooterData() {
      setError(false);
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_STRAPI_API_URL}/api/footer/${slug}?locale=${locale}&preview=${preview}`,
      )
        .then((response) => {
          setFooterData(null);
          return response.json() as Promise<FooterData | FooterData[]>;
        })
        .then((data) => {
          if (Array.isArray(data)) {
            const localeData = data.find((item) => item?.locale === locale);
            setFooterData(localeData ? localeData : null);
          } else if (data?.locale === locale) {
            setFooterData(data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching footer data:', error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [slug, locale, preview],
  );

  return {
    data: footerData,
    error,
    loading,
  };
};
