import { Header, APIResponse } from '../../../types';

function convertHeader(header: APIResponse): Header {
  if (!header) return {} as Header;

  const content = header?.Content?.[0];
  const simplifiedCountries = content?.countries?.map((country) => ({
    ...country,
    defaultLanguage: country?.default_language?.name,
    countryCode: country.iso2_country_code,
  }));

  const navigation = content?.navigation?.map((nav) => ({
    id: nav.id,
    baseUrl: nav.base_url,
    menuItems: nav?.menu_items?.map((item) => ({
      id: item.id,
      label: item.label,
      path: item.path,
      internalName: item.internal_name,
      menuItemGroup: item?.menu_item_group?.map((group) => ({
        id: group.id,
        headline: group.headline,
        menuItems: group?.menu_items?.map((subItem) => ({
          id: subItem.id,
          label: subItem.label,
          path: subItem.path,
          internalName: subItem.internal_name,
        })),
      })),
    })),
  }));

  const convertedHeader: Header = {
    id: header.id,
    slug: header.slug,
    mainImage: content?.main_image?.url,
    languageUrl: content?.language_url,
    countryFlag: content?.country_flag?.url || '',
    countries: simplifiedCountries,
    infoContent: content?.info_content,
    navigation: navigation,
    domains: content?.domains,
    translations: content?.translation?.translations,
  };

  return convertedHeader;
}

export default convertHeader;
