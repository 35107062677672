import React from 'react';
import Footer from './components/Footer/Travelcentive';
import {
  DEFAULT_MODE,
  MODE_FOOTER,
  isPreviewEnabled,
} from './components/utils';
import { useMode, useSlug } from './hooks';

export default function App() {
  const enabledPreview = isPreviewEnabled();

  const slug = useSlug();
  const mode = useMode();

  return (
    <>
      {(mode === DEFAULT_MODE || mode === MODE_FOOTER) && (
        <Footer preview={enabledPreview} slug={slug} />
      )}
    </>
  );
}
