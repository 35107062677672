import React, { useEffect } from 'react';
import Modal from '../Modal';
import Dropdown from '../Dropdown';
import styles from './SwitchLanguageModal.module.scss';
import classNames from 'classnames';
import {
  SimplifiedCountry,
  Country,
  SimplifiedDomain,
  Translation,
} from '../../types';
import { useTranslation } from '../../locale/hooks';

type languageUrl = {
  countries: {
    id: number;
    name: string;
  }[];
  languages: {
    id: number;
    name: string;
  }[];
  url: string;
};

type props = {
  Trigger?: React.FC;
  countries: Country[];
  currency: string;
  isOpen?: boolean;
  closeModal?: () => void;
  languageUrl: languageUrl[];
  headerText?: string;
  domains: SimplifiedDomain[];
  translations: Translation[];
  userDetectedLocale: {
    isUserInWrongLocale: boolean;
    userCountryCode: string;
    userLocale: string;
  };
  warnWrongLocale: boolean;
};

const SwitchLanguageModal = ({
  Trigger,
  countries,
  isOpen = false,
  closeModal,
  languageUrl,
  headerText,
  domains,
  translations,
  userDetectedLocale,
  warnWrongLocale,
}: props) => {
  const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
    null,
  );
  const [languages, setLanguages] = React.useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>('');
  const [url, setUrl] = React.useState<string | null>(null);

  const translate = useTranslation(
    translations,
    warnWrongLocale ? userDetectedLocale?.userLocale : undefined,
  );

  const onClose = () => {
    if (closeModal) {
      closeModal();
    }
    document.cookie = 'hasSeenSwitchLanguageModal=true; path=/';
  };

  const onSelect = (selectedOption: string, type?: string) => {
    if (type === 'country') {
      setSelectedCountry(selectedOption);

      const language = countries.find(
        (country) => country.name === selectedOption,
      )?.defaultLanguage;

      setSelectedLanguage(language || '');
    } else if (type === 'language') {
      setSelectedLanguage(selectedOption);
    }
  };

  const getLanguageUrl = (
    languageUrls: languageUrl[],
    selectedCountry: string | null,
    selectedLanguage: string,
  ) => {
    if (!languageUrls || !selectedCountry || !selectedLanguage) {
      return null;
    }

    for (const languageUrl of languageUrls) {
      const countryMatch = languageUrl.countries?.some(
        (country) => country.name === selectedCountry,
      );
      const languageMatch = languageUrl.languages?.some(
        (language) => language.name === selectedLanguage,
      );
      if (countryMatch && languageMatch) {
        return languageUrl.url;
      }
    }

    return null;
  };

  const handleSubmit = () => {
    if (url) {
      const newUrl = new URL(url);
      newUrl.searchParams.set('selectedCountry', selectedCountry || '');
      newUrl.searchParams.set('selectedLanguage', selectedLanguage);
      window.location.href = newUrl.href;
    } else {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('selectedCountry', selectedCountry || '');
      currentUrl.searchParams.set('selectedLanguage', selectedLanguage);
      window.history.replaceState({}, '', currentUrl.href);
    }

    document.cookie = 'hasSeenSwitchLanguageModal=true; path=/';
  };

  useEffect(() => {
    if (selectedCountry) {
      const country: SimplifiedCountry | undefined = countries?.find(
        (country) => country.name === selectedCountry,
      );
      const countryLanguages =
        (country &&
          country?.languages?.map((language) => language.name || '')) ||
        [];
      setLanguages(countryLanguages);

      if (
        countryLanguages.length === 1 &&
        (selectedLanguage === '' || selectedLanguage === null)
      ) {
        setSelectedLanguage(countryLanguages[0]);
      }
      if (
        country?.defaultLanguage &&
        (selectedLanguage === '' || selectedLanguage === null)
      ) {
        setSelectedLanguage(country.defaultLanguage || '');
      }
    } else {
      setLanguages([]);
    }
  }, [countries, selectedCountry]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const country = url.searchParams.get('selectedCountry');
    const language = url.searchParams.get('selectedLanguage');

    if (
      !selectedCountry &&
      userDetectedLocale?.userCountryCode &&
      warnWrongLocale &&
      !country &&
      !language
    ) {
      const country = countries?.find(
        (country) =>
          country.countryCode?.toLocaleLowerCase() ===
          userDetectedLocale?.userCountryCode.toLocaleLowerCase(),
      );
      setSelectedCountry(
        country?.name || domains?.[0]?.countries?.[0]?.name || null,
      );
      return;
    }

    if (country && language) {
      setSelectedCountry(country);
      url.searchParams.delete('selectedCountry');

      setSelectedLanguage(language);
      url.searchParams.delete('selectedLanguage');

      history.replaceState(null, '', url);
    }
  }, [selectedCountry, userDetectedLocale?.userCountryCode]);

  useEffect(() => {
    const url = getLanguageUrl(languageUrl, selectedCountry, selectedLanguage);
    setUrl(url);
  }, [languageUrl, selectedCountry, selectedLanguage]);

  return (
    <Modal
      body={
        <div className={classNames(styles.container)}>
          {headerText && (
            <p className={classNames(styles.headerText)}>{headerText}</p>
          )}
          <div className={classNames(styles.Dropdown)}>
            <Dropdown
              onSelect={onSelect}
              title={translate['switchlanguagemodal.country']}
              options={countries?.map((country) => country.name || '')}
              type="country"
              defaultValue={selectedCountry || ''}
            />
          </div>
          <div className={classNames(styles.Dropdown)}>
            <Dropdown
              onSelect={onSelect}
              title={translate['switchlanguagemodal.dropdown.language']}
              options={languages}
              disabled={languages.length === 0}
              defaultValue={selectedLanguage}
              type="language"
            />
          </div>
        </div>
      }
      buttonText={translate['switchlanguagemodal.button.action']}
      onSubmit={handleSubmit}
      Trigger={Trigger}
      isOpen={isOpen}
      closeModal={onClose}
      actionDisabled={!selectedCountry || !selectedLanguage}
    />
  );
};

export default SwitchLanguageModal;
