import { useSearchParams } from 'react-router-dom';
import { DEFAULT_MODE, DEFAULT_SLUG } from '../components/utils';

declare global {
  interface Window {
    __SLUG__: string;
    __MODE__: string;
    __LOCALE__: string;
  }
}

export const useSlug = () => {
  const [searchParams] = useSearchParams();
  const slug =
    (typeof window !== 'undefined' && window?.__SLUG__) ||
    searchParams?.get('slug') ||
    DEFAULT_SLUG;

  return slug;
};

export const useMode = () => {
  const [searchParams] = useSearchParams();
  const mode =
    (typeof window !== 'undefined' && window?.__MODE__) ||
    searchParams?.get('mode') ||
    DEFAULT_MODE;

  return mode;
};
