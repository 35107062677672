import { useSearchParams } from 'react-router-dom';
import { DEFAULT_LOCALE, GLOBAL_LOCALE } from '../components/utils';
import { Translation } from '../types';
import { getTranslationsByLocale } from './geoLocalization';

export const useTranslation = (
  translations: Translation[],
  detectedLocale?: string,
) => {
  const locale = useLocale(detectedLocale, GLOBAL_LOCALE);

  return getTranslationsByLocale(locale, translations);
};

export const useLocale = (detectedLocale?: string, defaultLocale?: string) => {
  const [searchParams] = useSearchParams();
  const locale =
    detectedLocale ||
    (typeof window !== 'undefined' && window?.__LOCALE__) ||
    searchParams?.get('locale') ||
    defaultLocale ||
    DEFAULT_LOCALE;

  return locale;
};
