import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TopNavigation from '../TopNavigation';

import styles from './Mainnav.module.scss';
import InfoContent from '../InfoContent';
import { faHeart, faPhone } from '@fortawesome/free-solid-svg-icons';

import MarketLanguage from '../MarketLanguage';

const Mainnav = ({
  className,
  closeMobileMenu,
  countryFlag,
  navigation,
  phone,
  favorite,
  openModal,
  withNavServices,
}) => {
  return (
    <nav className={classNames(styles.nav, className, 'mainnav')}>
      {withNavServices && (
        <div className={styles.navServices}>
          {phone && (
            <InfoContent
              icon={phone.icon_name === 'icon-phone' ? faPhone : faHeart}
              url={phone.url}
            />
          )}
          {favorite && (
            <InfoContent
              icon={favorite.icon_name === 'icon-phone' ? faPhone : faHeart}
              url={favorite.url}
            />
          )}

          <MarketLanguage
            currency="EUR"
            flagImage={countryFlag}
            openModal={openModal}
          />
        </div>
      )}
      <TopNavigation
        navigation={navigation}
        closeMobileMenu={closeMobileMenu}
        className={classNames(styles.mainnav)}
      />
    </nav>
  );
};

Mainnav.propTypes = {
  className: PropTypes.string,
  closeMobileMenu: PropTypes.func,
};

export default Mainnav;
