import React from 'react';
import classNames from 'classnames';
import styles from './MarketLanguage.module.scss';
import SwitchLanguageModal from '../SwitchLanguageModal';

const MarketLanguage = ({ currency, flagImage, openModal, ...rest }) => {
  const Trigger = () => (
    <div className={classNames(styles.content)} onClick={openModal || null}>
      <img src={flagImage} alt="marketLanguageFlag" width={25} height={20} />
      <span className={styles.currency}>{currency}</span>
    </div>
  );

  if (openModal) {
    return Trigger();
  }

  return (
    <div>
      <SwitchLanguageModal Trigger={Trigger} {...rest} />
    </div>
  );
};

export default MarketLanguage;
