import React, { Suspense, useMemo } from 'react';
import { useFooterData } from '../hooks/useFooterData';

import styles from './Footer.module.scss';
import { useLocale } from '../../../locale/hooks';

type Props = {
  preview: boolean;
  slug: string;
};

const Footer = ({ slug, preview }: Props) => {
  const locale = useLocale();

  const { data } = useFooterData(slug, preview, locale);

  const logo = useMemo(() => {
    return {
      url: data?.content?.[0] ? data?.content?.[0]?.logo?.url : '',
      name: data?.content?.[0]?.logo?.name || '',
      width: data?.content?.[0]?.logo?.width || 0,
      height: data?.content?.[0]?.logo?.height || 0,
    };
  }, [data?.content]);

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <ul className={styles.copyright}>
          <li>{data?.content?.[0]?.copyright}</li>
        </ul>
        <ul className={styles.links}>
          {data?.content?.[0]?.links?.map((link) => (
            <li key={link?.id}>
              <a href={link?.path}>{link?.label}</a>
            </li>
          ))}
        </ul>
        <div className={styles.logoWrapper}>
          <img
            className={styles.logo}
            src={logo.url || ''}
            alt={logo.name}
            width={logo.width}
            height={logo.height}
          />
        </div>
      </div>
    </footer>
  );
};

const FooterSuspense = (props: Props) => {
  return (
    <Suspense>
      <Footer {...props} />
    </Suspense>
  );
};

export default FooterSuspense;
