import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import HeaderStyles from '../../../components/Header/Travelcentive/Header.module.scss';
import '../../../components/OffCanvas/OffCanvas.module.scss';

export default (styles) => {
  class Menu extends Component {
    static defaultProps = {
      id: '',
      noOverlay: false,
      onStateChange: () => {},
      outerContainerId: '',
      pageWrapId: '',
      styles: {},
      width: 300,
      menu: 'slide',
    };

    props;

    constructor(props) {
      super(props);
      this.state = { isOpen: false };
    }

    componentWillMount() {
      if (this.props.isOpen) {
        this.toggleMenu();
      }
    }

    componentDidMount() {
      window.onkeydown = (e) => this.listenForClose(e);
    }

    componentWillReceiveProps(nextProps) {
      if (
        typeof nextProps.isOpen !== 'undefined' &&
        nextProps.isOpen !== this.state.isOpen
      ) {
        this.toggleMenu();
      }
    }

    componentWillUnmount() {
      window.onkeydown = null;

      this.clearWrapperStyles();
    }

    /**
     * Applies component-specific styles to external wrapper elements.
     */
    applyWrapperStyles() {
      if (styles.pageWrap && this.props.pageWrapId) {
        this.handleExternalWrapper(
          this.props.pageWrapId,
          styles.pageWrap,
          true,
        );
      }

      if (styles.outerContainer && this.props.outerContainerId) {
        this.handleExternalWrapper(
          this.props.outerContainerId,
          styles.outerContainer,
          true,
        );
      }
    }

    /**
     * Removes component-specific styles applied to external wrapper elements.
     */
    clearWrapperStyles() {
      if (_.get(styles, 'pageWrap') && this.props.pageWrapId) {
        this.handleExternalWrapper(
          this.props.pageWrapId,
          _.get(styles, 'pageWrap'),
          false,
        );
      }

      if (_.get(styles, 'outerContainer') && this.props.outerContainerId) {
        this.handleExternalWrapper(
          this.props.outerContainerId,
          _.get(styles, 'outerContainer'),
          false,
        );
      }
    }

    handleClose = () => {
      const { onClose } = this.props;
      if (onClose) {
        // if we have onClose handler it's assumed that off-canvas is controlled externally
        onClose();
      } else {
        this.toggleMenu();
      }
    };

    toggleMenu = () => {
      const newState = { isOpen: !this.state.isOpen };
      this.setState(newState, () => this.props.onStateChange(newState));
      const menu = document.querySelector(`.${HeaderStyles.bmMenuWrap}`);
      if (menu.classList.contains(HeaderStyles.show)) {
        menu.classList.remove(HeaderStyles.show);
      } else {
        menu.classList.add(HeaderStyles.show);
      }
    };

    listenForClose = (e) => {
      const event = e || window.event;

      if (
        this.state.isOpen &&
        (event.key === 'Escape' || event.keyCode === 27)
      ) {
        this.handleClose();
      }
    };

    render() {
      const { id, children, burgerButton, crossButton } = this.props;
      const { isOpen } = this.state;

      return (
        <div>
          {this.state.isOpen && (
            <div
              onClick={this.toggleMenu}
              className={classNames(HeaderStyles.mobileOverlay)}
            />
          )}
          <div id={id} className={classNames(HeaderStyles.bmMenuWrap)}>
            <div className={classNames(HeaderStyles.menuContainer)}>
              <nav className={classNames(HeaderStyles.navContent)}>
                {isOpen &&
                  React.Children.map(children, (item, index) => {
                    const extraProps = {
                      key: index,
                    };
                    return React.cloneElement(item, extraProps);
                  })}
              </nav>
            </div>
            <div onClick={this.handleClose}>{crossButton}</div>
          </div>
          <div
            className={classNames(HeaderStyles.offcanvasTrigger)}
            onClick={this.toggleMenu}
          >
            {burgerButton}
          </div>
        </div>
      );
    }
  }

  return Menu;
};
