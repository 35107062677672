import Cookies from 'js-cookie';

export const DEFAULT_LOCALE = 'de';
export const GLOBAL_LOCALE = 'en';

export const DEFAULT_SLUG = 'travelcentive';
export const DEFAULT_MODE = 'all';
export const MODE_HEADER = 'header';
export const MODE_FOOTER = 'footer';

export const isPreviewEnabled = () => {
  const previewData = Cookies.get('previewData');
  const data = JSON.parse(previewData || '{}');
  return !!data?.id;
};

export const ROOT_CONTAINER_CLASS_NAME = 'RootContainerClassName';

export const styleBodyOnOpen = (definedName) => {
  if (global.document && global.window) {
    const body = global.document.body;
    if (definedName) {
      if (!body.getAttribute('data-' + definedName)) {
        body.setAttribute('data-' + definedName, 1);
      } else {
        return null;
      }
    }
    if (!body.getAttribute('data-top')) {
      body.setAttribute('data-top', window.pageYOffset);
      body.classList.add('modalOpen');
      if (
        global.document.getElementsByClassName(ROOT_CONTAINER_CLASS_NAME)[0]
      ) {
        global.document.getElementsByClassName(
          ROOT_CONTAINER_CLASS_NAME,
        )[0].style.marginTop =
          -1 * Number(body.getAttribute('data-top')) + 'px';
      }
    }
    if (!body.getAttribute('data-modals')) {
      body.setAttribute('data-modals', 1);
    } else {
      body.setAttribute(
        'data-modals',
        Number(body.getAttribute('data-modals')) + 1,
      );
    }

    // somehow after the latest update of iOS iPhone is not able to render OffCanvas properly without adjusting
    // included css styles
    const css = '.bm-overlay { background: none; }';
    const head = global.document.head;
    const style = document.createElement('style');
    style.innerHTML = css;

    setTimeout(() => {
      head.appendChild(style);
      setTimeout(() => style.remove(), 0);
    }, 0);
  }
};

export const styleBodyOnClose = (definedName) => {
  if (global.document && global.window) {
    const body = global.document.body;
    if (definedName) {
      if (body.getAttribute('data-' + definedName)) {
        body.removeAttribute('data-' + definedName);
      } else {
        return null;
      }
    }
    if (body.classList.contains('modalOpen')) {
      body.setAttribute(
        'data-modals',
        Number(body.getAttribute('data-modals')) - 1,
      );
      if (Number(body.getAttribute('data-modals')) <= 0) {
        body.classList.remove('modalOpen');
        window.scrollTo(0, body.getAttribute('data-top'));
        body.removeAttribute('data-top');
        body.removeAttribute('data-modals');
        if (
          global.document.getElementsByClassName(ROOT_CONTAINER_CLASS_NAME)[0]
        ) {
          global.document.getElementsByClassName(
            ROOT_CONTAINER_CLASS_NAME,
          )[0].style.marginTop = 0;
        }
      }
    }
  }
};

export const mobileDeviceDetect = () => {
  if (typeof window !== 'undefined') {
    return window ? window.innerWidth < 767 : false;
  }
};
