import React, { FC } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

interface ButtonProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.Button, disabled && styles.disabled)}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
