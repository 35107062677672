import React, { Suspense, useEffect, useState } from 'react';
import classNames from 'classnames';
import _omit from 'lodash/omit';
import offcanvasStyles from '../../OffCanvas/OffCanvas.module.scss';
import OffCanvas from '../../OffCanvas';
import Logo from '../../Logo';
import MarketLanguage from '../../MarketLanguage';
import Mainnav from '../../Mainnav';
import mobileMenuStateEnhancer from './mobileMenuEnhancer';
import styles from './Header.module.scss';
import './Header.module.scss';
import { faHeart, faPhone } from '@fortawesome/free-solid-svg-icons';
import InfoContent from '../../InfoContent';
import { mobileDeviceDetect } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useHeaderData } from '../hooks/useHeaderData';
import convertHeaders from '../utils/convertHeaders';
import SwitchLanguageModal from '../../SwitchLanguageModal';
import { getUserLocaleData } from '../../../locale/geoLocalization';
import { useLocale, useTranslation } from '../../../locale/hooks';

const Header = (props) => {
  const { slug, isMobileMenuOpen, openMobileMenu, closeMobileMenu, preview } =
    props;

  const [isMobile, setIsMobile] = useState(false);
  const [languageSwitchModalOpen, setLanguageSwitchModalOpen] = useState(false);
  const [userDetectedLocale, setUserDetectedLocale] = useState(null);
  const [isInWrongRegion, setIsInWrongRegion] = useState(false);
  const [warnWrongLocale, setWarnWrongLocale] = useState(false);

  const locale = useLocale();
  const { data } = useHeaderData(slug, preview, locale);

  const {
    countryFlag,
    infoContent,
    mainImage,
    navigation,
    countries,
    languageUrl,
    domains,
    translations,
  } = convertHeaders(data);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileDeviceDetect());
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getRegion = async () => {
      if (!userDetectedLocale) {
        const userLocaleData = await getUserLocaleData(domains);
        setUserDetectedLocale(userLocaleData);
        setIsInWrongRegion(userLocaleData?.isUserInWrongLocale);
      }
    };

    // eslint-disable-next-line no-console
    getRegion().catch(console.error);
  }, [data]);

  const openModal = () => {
    setLanguageSwitchModalOpen(true);
  };

  const closeModal = () => {
    setLanguageSwitchModalOpen(false);
  };

  const translate = useTranslation(
    translations,
    warnWrongLocale ? userDetectedLocale.userLocale : undefined,
  );

  useEffect(() => {
    const hasSeenSwitchLanguageModal = document.cookie.includes(
      'hasSeenSwitchLanguageModal',
    );
    const shouldOpenModal = isInWrongRegion && !hasSeenSwitchLanguageModal;
    setWarnWrongLocale(shouldOpenModal);
    setLanguageSwitchModalOpen(shouldOpenModal);
  }, [isInWrongRegion]);

  const headerText = warnWrongLocale
    ? translate['switchlanguagemodal.language_mismatch']
    : undefined;

  const { className, ...rest } = props;

  const menuContent = (
    <Mainnav
      navigation={navigation}
      closeMobileMenu={closeMobileMenu}
      className={classNames(styles.mainnav)}
    />
  );

  const menuContentMobile = (
    <Mainnav
      navigation={navigation}
      closeMobileMenu={closeMobileMenu}
      className={classNames(styles.mainnav)}
      countryFlag={countryFlag}
      phone={infoContent?.find((item) => item.icon_name === 'icon-phone')}
      favorite={infoContent?.find((item) => item.icon_name === 'icon-heart')}
      openModal={openModal}
      closeModal={closeModal}
      withNavServices
    />
  );
  const burgerButton = (
    <div
      className={classNames(
        offcanvasStyles.OffCanvas,
        'header--offcanvas offcanvas--blank',
      )}
    >
      <div className="offcanvas">
        <div className="bm-burger-button">
          <FontAwesomeIcon
            className={classNames(styles.offcanvasTrigger)}
            icon={faBars}
          />
        </div>
      </div>
    </div>
  );

  const crossButton = (
    <div>
      <FontAwesomeIcon
        className={classNames(styles.BmCrossButton)}
        icon={faXmark}
      />
    </div>
  );

  const headerParams = _omit(rest, ['route']);

  return (
    <div className={classNames(styles.headerContainer)}>
      <header
        className={classNames(styles.header, className)}
        {...headerParams}
      >
        <div>
          <div className={styles.container}>
            <div className={styles.brandContainer}>
              <a href="/">
                <Logo className={styles.brandImage} logoUrl={mainImage} />
              </a>
            </div>
            <ul className={styles.nav}>
              {infoContent?.map((item, index) => (
                <li key={index} className={classNames(styles.navItem)}>
                  <InfoContent
                    label={item.label}
                    icon={item.icon_name === 'icon-phone' ? faPhone : faHeart}
                    url={item.url}
                    customClass={
                      item.icon_name === 'icon-phone' ? null : styles.favLink
                    }
                  />
                </li>
              ))}
              <li className={classNames(styles.navItem)}>
                <MarketLanguage
                  currency="EUR"
                  flagImage={countryFlag}
                  countries={countries}
                  languageUrl={languageUrl}
                  isInWrongRegion={isInWrongRegion}
                  domains={domains}
                  translations={translations}
                  userDetectedLocale={userDetectedLocale}
                  isOpen={languageSwitchModalOpen}
                  onCloseModal={closeModal}
                  headerText={headerText}
                  warnWrongLocale
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.navContainer}>
          <div>
            {isMobile ? (
              <>
                <SwitchLanguageModal
                  countries={countries}
                  currency={'EUR'}
                  isOpen={languageSwitchModalOpen}
                  closeModal={closeModal}
                  languageUrl={languageUrl}
                  domains={domains}
                  translations={translations}
                  userDetectedLocale={userDetectedLocale}
                  headerText={headerText}
                  warnWrongLocale
                />
                <OffCanvas
                  isOpen={isMobileMenuOpen}
                  onEntered={openMobileMenu}
                  onExited={closeMobileMenu}
                  burgerButton={burgerButton}
                  crossButton={crossButton}
                  className={classNames(
                    offcanvasStyles.OffCanvas,
                    'header--offcanvas',
                  )}
                  side="left"
                >
                  <div className={classNames(styles.offcanvasTitle)}>
                    {translate['switchlanguagemodal.menu_title']}
                  </div>
                  <div
                    className={classNames(
                      className,
                      'offcanvas--contentWrapper',
                    )}
                  >
                    {menuContentMobile}
                  </div>
                </OffCanvas>
              </>
            ) : (
              menuContent
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

const SuspenseHeader = (props) => (
  <Suspense>
    <Header {...props} />
  </Suspense>
);

export default mobileMenuStateEnhancer(SuspenseHeader);
