import { GLOBAL_LOCALE } from '../components/utils';
import { SimplifiedCountry, SimplifiedDomain, Translation } from '../types';

const isUserInWrongLocale = (domains: SimplifiedDomain[], locale: string) => {
  if (!domains) return false;

  const hostname = window.location.hostname;
  const domain = domains.find((domain) => hostname.includes(domain.url || ''));
  const countries = domain?.countries || [];
  const foundLanguage = getLanguageByLocale(locale, countries);

  return !foundLanguage;
};

const mapCountryCodeToLocale = (
  country_code: string,
  domains: SimplifiedDomain[],
) => {
  if (!domains) return GLOBAL_LOCALE;
  for (const domain of domains) {
    if (!domain || !domain?.countries) continue;
    for (const country of domain.countries) {
      if (country?.iso2_country_code === country_code) {
        return country.default_language?.iso2_language_code || GLOBAL_LOCALE;
      }
    }
  }
  return GLOBAL_LOCALE;
};

export type UserLocale = {
  isUserInWrongLocale: boolean;
  userCountryCode: string;
  userLocale: string;
};

export const getGeoData = async () => {
  const response = await fetch(`${process.env.REACT_APP_IP_API}`);
  const data = await response.json();
  const ip = data.ip;
  const geoResponse = await fetch(
    `${process.env.REACT_APP_GEOLOCALIZATION_API}${ip}`,
  );
  return geoResponse.json();
};

export const getUserLocaleData = async (
  domains: SimplifiedDomain[],
): Promise<UserLocale | undefined> => {
  try {
    const geoData = await getGeoData();
    const country_code = geoData?.country_code2;
    const locale =
      mapCountryCodeToLocale(country_code?.toLowerCase(), domains) ||
      GLOBAL_LOCALE;
    return {
      isUserInWrongLocale: isUserInWrongLocale(domains, locale),
      userCountryCode: country_code,
      userLocale: locale,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getLanguageByLocale = (
  locale: string,
  countries: SimplifiedCountry[],
) => {
  if (!countries) return;

  for (const country of countries) {
    if (!country.languages) continue;
    for (const language of country.languages) {
      if (language.iso2_language_code === locale) {
        return language;
      }
    }
  }

  return;
};

export const getTranslationsByLocale = (
  locale: string,
  translations: Translation[],
) => {
  if (!translations) return {};
  const translationMap: Record<string, string> = {};
  translations.forEach((entry) => {
    translationMap[entry.key] = entry[locale] ?? entry[GLOBAL_LOCALE];
  });

  return translationMap;
};
