import React, { useEffect } from 'react';
import classNames from 'classnames';
/* import PropTypes from 'prop-types'; */
import Collapsible from '../Collapsible';
import StaticPre from './StaticPre';
import StaticPost from './StaticPost';
import styles from './TopNavigation.module.scss';
import { mobileDeviceDetect } from '../utils';
const TopNavigation = ({ navigation }) => {
  const [isMobile, setIsMobile] = React.useState(mobileDeviceDetect());
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileDeviceDetect());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeMobileMenu = () => {};

  const renderMobileMenu = () => {
    let navTabs = [];
    if (navigation?.length > 0 && navigation[0].menuItems?.length > 0) {
      navTabs = convertData(navigation[0].menuItems);
    }
    return navTabs && navTabs.length > 0 ? (
      <div className={`${styles.navigation} ${styles.mobileNavigation}`}>
        <StaticPre />
        {navTabs.map((tab, key) => {
          const { headline, headlineLink, navSections, linkout } = tab;
          return (
            headline &&
            (headlineLink ? (
              <a
                key={key}
                className={styles.navigationItem}
                href={headlineLink}
                target={linkout ? '_blank' : '_self'}
                /* onClick={this.closeMobileMenu} */
              >
                <span className={styles.navigationLink}>{headline}</span>
              </a>
            ) : (
              navSections &&
              navSections.length > 0 && (
                <Collapsible
                  key={key}
                  className={styles.navigationItemCollapsible}
                  bsClass="navigationItemCollapsible"
                  defaultExpanded={false}
                  header={
                    <span className={styles.navigationItem}>
                      <span className={styles.navigationLink}>{headline}</span>
                    </span>
                  }
                >
                  {renderMobileNavSections(navSections)}
                </Collapsible>
              )
            ))
          );
        })}
        <StaticPost />
      </div>
    ) : null;
  };

  const renderMobileNavSections = (navSections) => {
    if (navSections && navSections.length > 0) {
      return navSections.map((section, key) => {
        const { headline, headlineLink, linkout, navLinks } = section;

        return (
          headline &&
          (headlineLink ? (
            <a
              href={headlineLink}
              target={linkout ? '_blank' : '_self'}
              className={styles.navigationTitle}
              onClick={closeMobileMenu}
            >
              {headline}
            </a>
          ) : (
            navLinks &&
            navLinks.length > 0 && (
              <Collapsible
                key={key}
                className={styles.navigationItemCollapsible}
                bsClass="navigationItemCollapsible"
                defaultExpanded={false}
                header={
                  <span className={styles.navigationTitle}>{headline}</span>
                }
              >
                {renderNavLinks(navLinks, true)}
              </Collapsible>
            )
          ))
        );
      });
    }
  };

  const renderNavLinks = (navLinks, isCollapsible = false) => {
    return (
      <div className={styles.subnavigationWrapper}>
        <ul className={styles.subnavigation}>
          {navLinks.map((link, linkKey) => {
            const text = link.text;
            const url = link.link;

            const linkout = link.linkout;
            return (
              text &&
              url && (
                <li key={linkKey} className={styles.subnavigationItem}>
                  <a
                    href={url}
                    target={linkout ? '_blank' : '_self'}
                    className={styles.subnavigationLink}
                    onClick={isCollapsible ? closeMobileMenu : undefined}
                  >
                    {text}
                  </a>
                </li>
              )
            );
          })}
        </ul>
      </div>
    );
  };

  const renderNavSections = (navSections) => {
    return (
      <div className={styles.navigationPopup}>
        <div>
          <div className={styles.navigationColumns}>
            {navSections.map((section, key) => {
              const { headline, headlineLink, linkout, navLinks } = section;
              return (
                headline && (
                  <div key={key} className={styles.navigationCol}>
                    {(headlineLink || (navLinks && navLinks.length > 0)) && (
                      <div className={styles.navigationTitle}>
                        {headlineLink ? (
                          <a
                            href={headlineLink}
                            target={linkout ? '_blank' : '_self'}
                          >
                            {headline}
                          </a>
                        ) : (
                          headline
                        )}
                      </div>
                    )}
                    {!headlineLink &&
                      navLinks &&
                      navLinks.length > 0 &&
                      renderNavLinks(navLinks)}
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  function convertData(apiData) {
    const navTabs = [];

    apiData.forEach((menu) => {
      const navSections = menu.menuItemGroup.map((group) => {
        return {
          headline: group.headline,
          navLinks: group.menuItems.map((item) => {
            return {
              text: item.label,
              link: item.path,
            };
          }),
        };
      });

      const navtab = {
        headline: menu.label,
        headlineLink: menu.path,
        navSections: navSections,
      };
      navTabs.push(navtab);
    });
    return navTabs;
  }

  const renderMenu = () => {
    let navTabs = [];

    if (navigation?.length > 0 && navigation[0].menuItems?.length > 0) {
      navTabs = convertData(navigation[0].menuItems);
    }

    return navTabs && navTabs.length > 0 ? (
      <ul className={`${styles.navigation} ${styles.desktopNavigation}`}>
        <StaticPre />
        {navTabs.map((tab, key) => {
          const { headline, headlineLink, navSections, linkout } = tab;
          return (
            headline &&
            (headlineLink ? (
              <li key={key} className={styles.navigationItem}>
                <a
                  href={headlineLink}
                  className={styles.navigationLink}
                  target={linkout ? '_blank' : '_self'}
                >
                  <span className={styles.separator} />
                  <span className={styles.underline} />
                  {headline}
                </a>
              </li>
            ) : (
              navSections &&
              navSections.length > 0 && (
                <li
                  key={key}
                  className={classNames(
                    styles.navigationItem,
                    styles.navigationItemTitle,
                  )}
                >
                  <span className={styles.navigationLink}>
                    <span className={styles.separator} />
                    <span className={styles.underline} />
                    {headline}
                  </span>
                  {renderNavSections(navSections)}
                </li>
              )
            ))
          );
        })}
        <StaticPost />
      </ul>
    ) : null;
  };

  return isMobile ? renderMobileMenu() : renderMenu();
};

export default TopNavigation;
